// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.appContainer {
  width: 100%;
  height: 100vh;
  background-color: #c2dcfd;
}

.appHeaderContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.appHeaderLogo {
  height: 30px;
  aspect-ratio: 1 / 1;
  pointer-events: none;
}

.appHeaderTitle{
  display: flex;
  align-items: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  animation: heartBump .5s forwards infinite;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.Title {
  display: flex;
  justify-content: center;
  font-size: 32px;
  top: 50px;
  position: absolute;
}

.comigSoonString {
  position: absolute;
  top: 100px;
  display: flex;
  justify-content: center;
  font-size: 32px;
}

@keyframes heartBump {

  0%,
  100% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,mBAAmB;EACnB,oBAAoB;AACtB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,oBAAoB;EACpB,0CAA0C;AAC5C;;AAEA;EACE,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,6BAA6B;AAC/B;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,eAAe;EACf,SAAS;EACT,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,aAAa;EACb,uBAAuB;EACvB,eAAe;AACjB;;AAEA;;EAEE;;IAEE,mBAAmB;EACrB;;EAEA;IACE,qBAAqB;EACvB;AACF","sourcesContent":[".appContainer {\n  width: 100%;\n  height: 100vh;\n  background-color: #c2dcfd;\n}\n\n.appHeaderContainer {\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n}\n\n.appHeaderLogo {\n  height: 30px;\n  aspect-ratio: 1 / 1;\n  pointer-events: none;\n}\n\n.appHeaderTitle{\n  display: flex;\n  align-items: center;\n}\n\n.App-logo {\n  height: 40vmin;\n  pointer-events: none;\n  animation: heartBump .5s forwards infinite;\n}\n\n.App-header {\n  min-height: 100vh;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  font-size: calc(10px + 2vmin);\n}\n\n.Title {\n  display: flex;\n  justify-content: center;\n  font-size: 32px;\n  top: 50px;\n  position: absolute;\n}\n\n.comigSoonString {\n  position: absolute;\n  top: 100px;\n  display: flex;\n  justify-content: center;\n  font-size: 32px;\n}\n\n@keyframes heartBump {\n\n  0%,\n  100% {\n    transform: scale(1);\n  }\n\n  50% {\n    transform: scale(1.2);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
