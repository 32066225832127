import logo from './assets/alpaca.png';
import alpacaLogo from './assets/alpacaLogo.png';
import './App.css';
import { React, useEffect, useState } from 'react';
import Feedback from './screen/Feedback';

function App() {
  const urlParams = new URLSearchParams(new URL(window.location.href).search);
  const [pageNavigation, setPageNavigation] = useState('feedback');

  useEffect(() => {
    document.title = 'The Alpaca';
  }, []);

  return (
    <div className="appContainer">
      {false &&
        <div className='appHeaderContainer'>
          <img src={alpacaLogo} className="appHeaderLogo" alt="Logo Alpaca" />
          <div className='appHeaderTitle'>Alpaca</div>
        </div>}
      {pageNavigation == 'home' ?
        <header className="App-header">
          <div className='Title'>
            Alpaca
          </div>
          <div className='comigSoonString'>Coming Soon ...</div>
          <img src={logo} className="App-logo" alt="logo" />
        </header>
        : pageNavigation == 'feedback' ?
          <Feedback appRef={urlParams.get('appRef')} />
          : null}
    </div>
  );
}

export default App;

