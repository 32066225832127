import React, { useEffect, useState } from "react";
import './Feedback.css';
import imgFeedback from '../assets/feedbackPage.png';
import SelectInputComponent from "../components/SelectInputComponent";
import PopupConfirmationComponent from "../components/PopupConfirmationComponent";

const Feedback = ({ appRef }) => {
    const [firstTimeSubmit, setFirstTimeSubmit] = useState(false);      //evito che attivi validateSubmit come primo caricamento della pagina
    const [textArea, setTextArea] = useState('');
    const [mail, setMail] = useState('');
    const [appReference, setAppReference] = useState(appRef ?? 'none');
    const [showConfirmPopup, setShowConfirmPopup] = useState(-1);     //-1 popup nascosto  //0==false popup mostra errore  //1==true popup mostra conferma
    const [errorObj, setErrorObj] = useState({ textArea: -1, mail: -1, appReference: -1 });    //-1 error nascosto  //0==false mostra errore  //1==true non mostra nulla

    const validateMailSyntax = (mail) => {
        let regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regexEmail.test(mail);
    }

    const validateSubmit = () => {
        setErrorObj({
            textArea: textArea.length > 100 ? 1 : 0,
            mail: validateMailSyntax(mail) ? 1 : 0,
            appReference: appReference != 'none' ? 1 : 0
        });
    }

    const sendFeedback = () => {
        if (errorObj.textArea == 1 && errorObj.mail == 1 && errorObj.appReference == 1) {
            fetch('https://alpacaoptimize.com/api/feedback',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'xalpacax': 'b2ab2fd856399a7bc9071ddc3f427bd7'
                    },
                    body: JSON.stringify({
                        "mail": mail,
                        "feedbackText": textArea,
                        "appReference": appReference,
                        "create_at": new Date()
                    })
                }
            ).then(res => res.json()).then(data => data.message == 'Success' ? setShowConfirmPopup(1) : setShowConfirmPopup(0)).catch(error => console.log('error ' + error));
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [appRef]);

    useEffect(() => {
        if (showConfirmPopup == -1) {
            setMail('');
            setTextArea('');
            setAppReference('none');
        }
    }, [showConfirmPopup]);

    useEffect(() => {
        if (firstTimeSubmit) {
            if (textArea.length > 100) {
                validateSubmit();
            }
            if (validateMailSyntax(mail)) {
                validateSubmit();
            }
            if (appReference != 'none') {
                validateSubmit();
            }
        }
    }, [textArea, mail, appReference]);

    return (
        <div className="feedbackContainer">
            <div className="feedbackColumn1Container">
                <div className="feedbackFormContainer">
                    <div className="feedbackFormTextContainer">
                        <div className="feedbackFormTitle">Help us get better</div>
                        <div className="feedbackFormSubtitle">Please tell us more about the experience you had</div>
                    </div>
                    <div className="feedbackFormTextAreaContainer">
                        <textarea className="feedbackTextArea" style={errorObj.textArea == 0 ? { border: '1px solid #ff0000' } : {}} placeholder="" minLength={100} value={textArea} onChange={(event) => setTextArea(event.target.value)} />
                        <label className="feedbackLabel">Your feedback...</label>
                        {errorObj.textArea == 0 &&
                            <label className="feedbackLabelError">Feedback must be longer than 100 characters</label>}
                    </div>
                    <div className="feedbackFormInputContainer">
                        <SelectInputComponent
                            id={'appReference'}
                            defaultValue={appReference}
                            setValue={setAppReference}
                            values={[
                                { name: 'Alpaca Sticky Add To Cart Pro', value: 'alpacaStickyBar', style: {} }
                            ]}
                            errorSubmit={errorObj.appReference == 0 ? true : false}
                        />
                    </div>
                    <div className="feedbackFormInputContainer">
                        <input className="feedbackInputText" style={errorObj.mail == 0 ? { border: '1px solid #ff0000' } : {}} placeholder="" value={mail} onChange={(event) => setMail(event.target.value)} />
                        <label className="feedbackLabel">Email</label>
                    </div>
                    <div className="feedbackButtonContainer">
                        <div className="feedbackButton" onClick={() => {
                            if (!firstTimeSubmit) {
                                setFirstTimeSubmit(true);
                            }
                            validateSubmit();
                            sendFeedback();
                        }}>submit</div>
                    </div>
                </div>
            </div>
            <div className="feedbackColumn2Container">
                <img src={imgFeedback} className="feedbackImg" alt="img" />
            </div>
            {showConfirmPopup != -1 &&
                <PopupConfirmationComponent
                    type={showConfirmPopup == 1 ? 'success' : 'error'}
                    setOverlayPopupConfirmation={setShowConfirmPopup}
                />}
        </div>
    );
}

export default Feedback;
