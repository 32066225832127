// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.selectInputContainer {
    position: relative;
}

.selectInputSelect {
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: 5px;
    border: .5px solid #cccccc;
    border-radius: 10px;
    font-weight: 500;
    outline: none;
    cursor: default;
}

.selectInputSelectFocus {
    border: 1.8px solid #2196F3;
    box-shadow: 0px 0px 3px #2196F3;
}

.selectInputIcon {
    position: absolute;
    right: 0;
    margin-right: 15px;
}

.selectInputOptionContainer {
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 1;
    width: 100%;
    padding: 5px;
    border: .5px solid #cccccc;
    border-radius: 10px;
    background-color: #ffffff;
    cursor: pointer;
}

.selectInputOption {
    padding: 2px;
}

.selectInputOption:hover {
    background-color: #0064FF;
    color: #ffffff;
}`, "",{"version":3,"sources":["webpack://./src/components/SelectInputComponent.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,YAAY;IACZ,0BAA0B;IAC1B,mBAAmB;IACnB,gBAAgB;IAChB,aAAa;IACb,eAAe;AACnB;;AAEA;IACI,2BAA2B;IAC3B,+BAA+B;AACnC;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,UAAU;IACV,WAAW;IACX,YAAY;IACZ,0BAA0B;IAC1B,mBAAmB;IACnB,yBAAyB;IACzB,eAAe;AACnB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,yBAAyB;IACzB,cAAc;AAClB","sourcesContent":[".selectInputContainer {\n    position: relative;\n}\n\n.selectInputSelect {\n    display: flex;\n    align-items: center;\n    flex-direction: row;\n    padding: 5px;\n    border: .5px solid #cccccc;\n    border-radius: 10px;\n    font-weight: 500;\n    outline: none;\n    cursor: default;\n}\n\n.selectInputSelectFocus {\n    border: 1.8px solid #2196F3;\n    box-shadow: 0px 0px 3px #2196F3;\n}\n\n.selectInputIcon {\n    position: absolute;\n    right: 0;\n    margin-right: 15px;\n}\n\n.selectInputOptionContainer {\n    display: flex;\n    flex-direction: column;\n    position: absolute;\n    z-index: 1;\n    width: 100%;\n    padding: 5px;\n    border: .5px solid #cccccc;\n    border-radius: 10px;\n    background-color: #ffffff;\n    cursor: pointer;\n}\n\n.selectInputOption {\n    padding: 2px;\n}\n\n.selectInputOption:hover {\n    background-color: #0064FF;\n    color: #ffffff;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
