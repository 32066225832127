import React, { useState, useContext } from "react";
import './PopupConfirmationComponent.css';

const PopupConfirmationComponent = ({ type, setOverlayPopupConfirmation }) => {

    return (
        <div className="popupConfirmationContainer">
            {type == 'success' || type == 'error' ?
                <svg>

                    <circle
                        className='popupConfirmationCirle'
                        cx={'50%'}
                        cy={'50%'}
                        r={50}
                        fill='transparent'
                        stroke={type == 'success' ? '#00ff009a' : type == 'error' ? '#ff00009a' : '#00b7ff9a'}
                    />
                    {type == 'success' ?
                        <path
                            className="popupConfirmationCheck"
                            fill="none"
                            d="M14.1 27.2l7.1 7.2 16.7-16.8"
                            onAnimationEnd={() => setOverlayPopupConfirmation(-1)}
                        />
                        : type == 'error' ? <path
                            className="popupConfirmationError"
                            fill="none"
                            d="M10 10 L90 90 M90 10 L10 90"
                            onAnimationEnd={() => setOverlayPopupConfirmation(-1)}
                        />
                            : null
                    }
                </svg>
                : <div className="popupConfirmationRequestContainer">
                    <div className="popupConfirmationRequestLabel">Sei sicuro di voler eliminare l'elemento?</div>
                    <div className="popupConfirmationRequestButtonContainer">
                        <div className="popupConfirmationRequestButtonConfirm" onClick={() => { console.log('Da settare') }}>Confirm</div>
                        <div className="popupConfirmationRequestButtonCancel" onClick={() => setOverlayPopupConfirmation(-1)}>Cancel</div>
                    </div>
                </div>}
        </div>
    );
}

export default PopupConfirmationComponent;