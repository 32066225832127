import React, { useEffect, useState } from "react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa6";
import './SelectInputComponent.css';

const SelectInputComponent = ({ id, defaultValue, setValue, values, dark, errorSubmit }) => {     //value array di oggetti con proprietà di value e name
    const [selectValue, setSelectValue] = useState({ name: 'Choose App', value: 'none', style: { width: '100%' } });
    const [visibleOption, setVisibleOption] = useState(false);

    useEffect(() => {
        for (let i = 0; i < values.length; i++) {
            if (values[i].value == defaultValue) {
                setSelectValue(values[i]);
                break;
            }
        }

        document.addEventListener('wheel', checkWheelMovement);
        document.addEventListener('click', function (event) {
            const targetElement = event.target;
            // Se il clic non è avvenuto all'interno del div specifico o del suo contenuto
            if (!targetElement.closest('#select' + id)) {
                setVisibleOption(false);
            }
        });
    }, [defaultValue, errorSubmit]);

    const handleChange = (value, func) => {
        func(value);
    };

    function checkWheelMovement() {
        setVisibleOption(false);
    }

    return (
        <div id={'select' + id} style={selectValue.style ?? {}} className="selectInputContainer">
            <div className={`selectInputSelect ${visibleOption ? 'selectInputSelectFocus' : {}}`}
                style={errorSubmit ? { border: '1px solid #ff0000' } : {}}
                onClick={() => setVisibleOption(!visibleOption)}
            >{selectValue.name}{visibleOption ? <FaAngleUp className="selectInputIcon" /> : <FaAngleDown className="selectInputIcon" />}</div>
            {
                visibleOption ?
                    <div className="selectInputOptionContainer" style={dark && { backgroundColor: '#111111', border: '.5px solid #000000' }}>
                        {values.map((obj) => {
                            return <div key={obj.value} className="selectInputOption" style={dark && { color: '#ffffff' }} onClick={() => { handleChange(obj, setSelectValue); setValue(obj.value); setVisibleOption(false); }}>{obj.name}</div>
                        })}
                    </div >
                    : null
            }
        </div>
    );
}

export default SelectInputComponent;